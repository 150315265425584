import React from 'react';
import BackgroundImage from 'gatsby-background-image';

import './style.scss';

class HeaderImage extends React.Component {
  render() {
    const { children, image } = this.props;

    // TODO: We should be able to automate a lot of the graphQL magic we do to get and format the
    // image, if we move it in here somehow. That probably requires a bit more query handling than
    // we're doing right now, though.

    return (
      <BackgroundImage fluid={image} className="" classId="header">
        {children}
      </BackgroundImage>
    );
  }
}

export default HeaderImage;
