import React from 'react';
import { Link } from 'gatsby';

const LocationLink = ({ to, location, children }) => {
  let classes = 'nav-item';
  if (to === location.pathname) {
    classes += ' active';
  }
  return (
    <li className={classes}>
      <Link to={to} className="nav-link">
        {children}
      </Link>
    </li>
  );
};

class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
    };
  }

  toggle = () => {
    this.setState(({ collapsed }) => ({ collapsed: !collapsed }));
  };

  render() {
    const { location, title } = this.props;

    const collapsed = this.state.collapsed ? 'collapse' : '';

    // Navbar flexes as a column unless we are wide enough that we aren't using the collapser.
    return (
      <nav className="navbar navbar-expand-lg navbar-dark flex-column flex-md-row bg-primary">
        <div className="container">
          <Link className="text-center" to="/">
            <h1 className="navbar-brand mb-0">{title}</h1>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggle}
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className={`navbar-nav-scroll navbar-collapse ${collapsed}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav bd-navbar-nav flex-column flex-lg-row">
              <LocationLink to="/individuals/" location={location}>
                For Individuals
              </LocationLink>
              <LocationLink to="/organizations/" location={location}>
                For Organizations
              </LocationLink>
              <LocationLink to="/policies/" location={location}>
                Policies
              </LocationLink>
              <LocationLink to="/subscribe/" location={location}>
                Subscribe
              </LocationLink>
              <LocationLink to="/contact/" location={location}>
                Contact
              </LocationLink>
            </ul>
          </div>
          <div className="navbar-nav flex-row ml-md-auto d-none d-md-flex" />
        </div>
      </nav>
    );
  }
}

export default Nav;
