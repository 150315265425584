import React from 'react';

import Nav from 'components/Nav';
import { siteMetadata } from '../../../gatsby-config';

import 'modern-normalize/modern-normalize.css';
import 'prismjs/themes/prism.css';
import 'scss/gatstrap.scss';
import './style.scss';

class Layout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div>
        <Nav title={siteMetadata.title} {...this.props} />
        <div className="container main-content shadow" id="mainContent">
          {children}
        </div>
      </div>
    );
  }
}

export default Layout;
